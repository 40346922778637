<template>
  <div class="page">
    <!--pages/myaddress/myaddress.wxml-->
    <div class="list">
      <div class="title flexcenter">
        <div class="f32b">配送地址</div>
        <div class="add" @click="addNewAddress()">+新增地址</div>
      </div>
		
      <div class="item" @click="selectAddress(idx)" v-for="(addr, idx) in address" :key="addr.id">
        <div class="line1">
          <span class="name">{{addr.real_name}}</span>
          <span>{{addr.phone}}</span>
        </div>
        <div class="line2 flexcenter">
          <div class="addr">{{addr.province}}{{addr.city}}{{addr.district}}{{addr.detail}}</div>
          <i @click.stop="editAddress(idx)" class="iconfont iconbianjidizhi"></i>
        </div>
      </div>
	  
    </div>
  </div>
</template>

<script>
  export default {
    name: 'myaddress',
    data() {
      return {
        page: 1,
        totalPage: 0,
        address: [],
        loading:false,
        mode:'address',
        skuids:'',
        family:'', //判断是不是家庭单
      }
    },
    created() {
		let mode = this.$route.query.mode;
	    if(typeof mode=='undefined'||mode!='checkout')
	    { 
	       this.mode = 'address';
	    }
	    else
	    {
	       this.mode = 'checkout';
	    }
	 
	    let skuids = this.$route.query.skuids;
	    if(typeof mode!='undefined')
	    { 
	       this.skuids = skuids;
	    }
		
		let family = this.$route.query.family;
	    if(typeof family != 'undefined' && family == '1')
	    {
	       this.family = family;
	    }
		 
		this.getAddressList(1,true);
    },
    methods: {
      /**
       * 获取地址数据
       */
      getAddressList:function(pageNo, override){
        let that = this;
        this.loading = true
        this.post(
          this.API.addressList,{}
        ).then(response => {
          let address = response.data.data;
          that.page = response.data.current_page     //当前的页号
          that.totalPage = response.data.last_page  //总页数
          that.address = override ? address : that.address.concat(address)
          that.loading = false
        })
      },
      selectAddress:function(idx){
		  console.log(this.mode);
        if(this.mode=='checkout') {
          let {skuids, family} = this
          let address = JSON.stringify(this.address[idx]);
          
          this.$router.push({
            name: 'spjs',
            query: {
              address,
              skuids,
              family,
            }
          })
        }
      },
      addNewAddress:function(){
        let {mode, skuids, family} = this
        
        this.$router.push({
          name: 'myaddressedit',
          query: {
            mode,
            skuids,
            family,
          }
        })
      },
      editAddress:function(idx){
        let address = JSON.stringify(this.address[idx]);
        let {mode, skuids, family} = this

        this.$router.push({
          name: 'myaddressedit',
          query: {
            address,
            mode,
            skuids,
            family,
          }
        })
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    min-height: 100%;
    background-color: #F5F5F5;
  }

  .list {
    margin: 24px;
    padding: 0 24px;
    border-radius: 6px;
    background-color: #FFFFFF;
  }
  .list .title {
    justify-content: space-between;
    height: 88px;
  }
  .list .title .add {
    font-size: 28px;
    color: #FEA30F;
  }

  .item .line1 {
    padding: 30px 0 16px;
    font-size: 28px;
    border-top: 1px solid #EEEEEE;
  }
  .item .line1 .name {
    margin-right: 10px;
  }
  .item .line2 {
    justify-content: space-between;
    padding-bottom: 28px;
  }
  .item .line2 .addr {
    width: 532px;
    font-size: 24px;
    color: #777777;
  }
  .item .iconfont {
    font-size: 32px;
    color: #C7C7C7;
  }

</style>
